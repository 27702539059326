import React from "react";
import {CheckBox, CheckBoxLabel, CheckBoxText, CheckBoxWrapper, Container, Title} from "../common/styles/Switcher";
import PropTypes from 'prop-types';
import { TooltipContainer, TooltipIcon, TooltipText } from "../common/styles/TooltipIcon";

export class Switcher extends React.Component {
    render() {
        return (
            <Container 
                justifyContent={this.props.justifyContent}
                className={this.props.className}
            >
                {this.props.activeText && this.props.inActiveText && this.props.positionText === 'left' &&
                    <Title positionText='left'>
                        {this.props.isChecked ? 
                        this.props.activeText : 
                        this.props.inActiveText}
                    </Title>
                } 
                <CheckBoxWrapper justifyContent={this.props.justifyContent}>
                    {this.props.labelText &&
                        <CheckBoxText>
                            {this.props.labelText}
                            {this.props.infoIcon && 
                                <TooltipContainer>
                                    <TooltipIcon tooltipMarginLeft={this.props.tooltipMarginLeft || "10px"}>
                                        {this.props.infoIcon}
                                    </TooltipIcon>
                                    <TooltipText>
                                        {this.props.infoIconTooltip}
                                    </TooltipText>
                                </TooltipContainer>
                            }
                        </CheckBoxText>}
                    <CheckBox 
                        data-testid='switch' 
                        id={`checkbox${this.props.switchId}`} 
                        type="checkbox" 
                        checked={this.props.isChecked}
                        backgroundActiveColor={this.props.backgroundActiveColor}
                        onChange={ev => this.props.handleChange(ev)}
                        activeDisabled={this.props.activeDisabled}
                    />                    
                    <CheckBoxLabel
                        data-testid='switch-label' 
                        htmlFor={`checkbox${this.props.switchId}`}
                        backgroundNotActiveColor={this.props.backgroundNotActiveColor}
                        switchColor={this.props.switchColor}
                        disabled={this.props.disabled}
                    />
                    {this.props.activeText && this.props.inActiveText && this.props.positionText === 'right' &&
                        <Title positionText='right'>
                            {this.props.isChecked ? 
                            this.props.activeText : 
                            this.props.inActiveText}
                        </Title>
                    } 
                </CheckBoxWrapper>
            </Container>
        )
    }
}

Switcher.propTypes = {
    switchId: PropTypes.number.isRequired
}
