import React from 'react';
import Form from 'react-bootstrap/Form';
import {reduxForm} from "redux-form";
import FormNavigation from '../../common/FormNavigation';
import {StyledExplanation} from "../../common/styles/FormStyles";
import PriceCalculationConsumerForm from "./includes/priceCalculationConsumer/PriceCalculationConsumerForm";
import PriceCalculationProducerForm from "./includes/priceCalculationProducer/PriceCalculationProducerForm";
import { connect } from 'react-redux';
import { compose } from 'redux';

export class ProductPriceCalculationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        clicked: false
    }
  }

  setSaveClicked(isClicked) {
      this.setState({ clicked: isClicked })
  }

  render() {
    return (
      // handleSubmit needed for local validation of fields
      <Form onSubmit={this.props.handleSubmit(() => undefined)}>
        <StyledExplanation>
          {
            this.props.isProducer 
            ? 
              <>
                Hier können Sie die Dienstleistungsentgelte pro Erzeugungsart festlegen. Im Falle einer Festpreisvergütung können Sie zusätzlich die Höhe des Festpreises angeben.
              </>
            : 
              <>
                Hier können Sie festlegen, wie Arbeitspreis und Grundpreis
                berechnet werden sollen. Außerdem können optional abweichende
                Displaynamen festgelegt werden.
              </>
          }
        </StyledExplanation>
          {this.props.isProducer? 
            <PriceCalculationProducerForm
              disabled={this.props.disabled}
            />:
            <PriceCalculationConsumerForm
              saveClicked={this.state.clicked}
              {...this.props}
            />}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        
        <FormNavigation
          onSaveHandler={this.props.onSaveProductOffer}
          onPreviousPageHandler={this.props.onPreviousPage}
          nextTabKey={this.props.nextTabKey}
          previousTabKey={this.props.previousTabKey}
          setSaveClicked={this.setSaveClicked.bind(this)}
        />
      </Form>
    );
  }
}

function mapStateToProps(state){
  console.log(state.productOffers.definition.formData)
  return {
      initialValues: state.productOffers.definition.formData,
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: "productDefinitionForm",  
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    touchOnChange: true,
  })
)(ProductPriceCalculationForm)
